import { Button, Col, Dropdown, Form, Icon, Menu, Modal, Row, Select, Spin, Upload } from 'antd';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { useState, useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Button_01 from '../../../../components/v2/button_01';
import LabeRequire from '../../../../components/v2/label-require';
import { errorNotification, successNotification } from '../../../../components/v2/notification';
import { uploadFileApi, uploadFileApiNew } from '../../../../controllers/orders-controller/api';
import { DeliveryContext } from '../..';
import ModalImportPreview from '../import-preview';
import { InboxOutlined } from '@ant-design/icons';

const { Dragger } = Upload;
const { Option } = Select;

const ModalImportOrder = ({ open, setOpen, handleDownLoadTemplate, orderData }) => {
  const intl = useIntl();
  const { setFileHash } = useContext(DeliveryContext);
  const [openPreview, setOpenPreview] = useState(false);
  const [isLoad, setIsLoad] = useState(false);
  const [orderType, setOrderType] = useState(undefined);

  useEffect(() => {
    setOrderType(undefined);
  }, [open]);

  console.log('orderType', orderType);

  const props = {
    multiple: false,
    showUploadList: false,
    action: '',
    accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    onChange(info) {
      const isXlxs = info.file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      if (!isXlxs) {
        errorNotification('You can only upload Xlxs file!');
        return false;
      }

      if (info.file) {
        fileUpload(info.file);
      }

      info = null;
    },
    beforeUpload() {
      return false;
    },
  };

  const fileUpload = async (file) => {
    setIsLoad(true);
    setOpenPreview(true);
    if (file) {
      let formFile = new FormData();
      formFile.append('file', file);
      formFile.append('importModule', orderType);
      try {
        const response = await uploadFileApiNew(formFile);
        if (_.get(response.data.data, 'fileHash')) {
          setFileHash({ ...response.data.data, orderDetailTypeCode: orderType });
          setOpenPreview(true);
          successNotification(_.get(response.data.status, 'message'));
        } else {
          errorNotification(_.get(response.data.status, 'message'));
          setOpenPreview(false);
        }
      } catch (error) {
        errorNotification(_.get(error.response.data.status, 'message'));
      }
    }
    setIsLoad(false);
  };

  const handleCancel = () => {
    setFileHash();
    setOpen(false);
  };

  const menu = (
    <Menu>
      <Menu.Item key="1" onClick={() => handleDownLoadTemplate('item')}>
        Delivery Order (Item)
      </Menu.Item>
      <Menu.Item key="2" onClick={() => handleDownLoadTemplate('parcel')}>
        Delivery Order (Parcel)
      </Menu.Item>
    </Menu>
  );

  const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;
  return (
    <Modal
      title={intl.formatMessage({ id: 'modalOrderImportDeliveryOrder', defaultMessage: 'Import Delivery Order' })}
      visible={open}
      onCancel={() => handleCancel()}
      width={650}
      centered={true}
      bodyStyle={{ padding: '12px 24px' }}
      footer={
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Dropdown overlay={menu} trigger={['click']}>
            <Button style={{ border: '1px solid', color: '#6390CF' }}>
              Download Template <Icon type="down" />
            </Button>
          </Dropdown>
          <Button_01 key="close" type="primary" btnwidth="wd_at" onClick={() => handleCancel()} disabled={isLoad}>
            <FormattedMessage id="btnClose" defaultMessage="Close" />
          </Button_01>
        </div>
      }
    >
      <Spin indicator={antIcon} spinning={isLoad}>
        <Form colon={false}>
          <Row gutter={[12, 0]}>
            <Col span={12}>
              <Form.Item
                label={
                  <LabeRequire text={intl.formatMessage({ id: 'deliveryFilterTitleOrderType', defaultMessage: 'Order Type' })} req={true} />
                }
              >
                <Select
                  allowClear={true}
                  placeholder={intl.formatMessage({ id: 'deliveryFilterPlaceholderOrderType', defaultMessage: 'Select Order Type' })}
                  filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  showSearch
                  onChange={(value) => setOrderType(value)}
                  value={orderType}
                >
                  {_.map(_.get(orderData, 'data.orderDetailTypeList'), (item) => (
                    <Option key={item.code}>{item.txt}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label={<LabeRequire text={intl.formatMessage({ id: 'modalOrderImportFile', defaultMessage: 'File' })} req={true} />}
              >
                {/* <Upload {...props}>
                  <Button style={{ width: '100%' }}>
                    <Icon type="upload" /> <FormattedMessage id="modalOrderClicktoupload" defaultMessage="Click to upload" />
                  </Button>
                </Upload> */}
                <Dragger {...props} disabled={orderType ? false : true}>
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p className="ant-upload-text">Click or drag file to this area to upload</p>
                  <p className="ant-upload-hint">Support for a single or bulk upload. Strictly prohibit from</p>
                  <p className="ant-upload-hint">uploading company data or other band files</p>
                </Dragger>
              </Form.Item>
            </Col>
            {/* <Col span={12}>
              <Form.Item
                label={
                  <LabeRequire
                    text={intl.formatMessage({ id: 'modalOrderImportTemplateCSV', defaultMessage: 'Template CSV' })}
                    req={false}
                  />
                }
              >
                <Button_01 key="upload" type="primary" style={{ margin: 'unset', width: '100%' }} onClick={() => handleDownLoadTemplate()}>
                  <FormattedMessage id="modalOrderDownloadTemplate" defaultMessage="Download Template" />
                </Button_01>
              </Form.Item>
            </Col> */}
          </Row>
        </Form>
      </Spin>
      {openPreview && (
        <ModalImportPreview open={openPreview} setOpen={setOpenPreview} setOpenImport={setOpen} />
      )}
    </Modal>
  );
};

export default ModalImportOrder;
