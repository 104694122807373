import React, { useContext } from 'react';
import CollapseCustom from '../../../../../components/collapse-custom';
import { FormattedMessage, useIntl } from 'react-intl';
import DeliveryActionColumn from './action-column';
import { Input, Form, Select, DatePicker, Row, Col, Icon } from 'antd';
import LabeRequireForm from '../../../../../components/label-required-form';
import { DeliveryShipmentContext } from '..';
import _ from 'lodash';
import LoadmoreCustomer from '../../../../../components/loadmore/customer';
import Button_01 from '../../../../../components/v2/button_01';
import Button_02 from '../../../../../components/v2/button_02';

const { RangePicker } = DatePicker;
const { Option } = Select;

const DeliveryShipmentAction = () => {
  const intl = useIntl();
  const { searchShipment, orderData, customerData, onFilterOrder, searchOrder, setSearchOrder, setTrigger } = useContext(
    DeliveryShipmentContext
  );

  // console.log("customerData",customerData)

  const handleApply = () => {
    setTrigger((event) => !event);
    searchShipment.setShipmentPage(1);
  };

  const handleReset = () => {
    setTrigger((event) => !event);
    searchShipment.setShipmentPage(1);
    setSearchOrder({ searchDate: _.get(searchOrder, 'searchDate') });
  };

  const formFilter = () => {
    return (
      <Form colon={false} className="deliveryForm">
        <Row gutter={[24, 2]}>
          <Col span={8}>
            <Form.Item
              label={<LabeRequireForm text={intl.formatMessage({ id: 'deliveryFilterTitleDO', defaultMessage: 'DO NO.' })} req={false} />}
            >
              <Input
                allowClear={true}
                placeholder={intl.formatMessage({ id: 'deliveryFilterPlaceholderDO', defaultMessage: 'Enter DO No.' })}
                onChange={(e) => onFilterOrder(e.target.value, 'doNo')}
                value={_.get(searchOrder, 'doNo')}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={
                <LabeRequireForm text={intl.formatMessage({ id: 'deliveryFilterTitleCustomer', defaultMessage: 'Customer' })} req={false} />
              }
            >
              <Input
                allowClear={true}
                placeholder={intl.formatMessage({ id: 'deliveryFilterPlaceholderCustomer', defaultMessage: 'Enter Customer' })}
                onChange={(e) => onFilterOrder(e.target.value, 'customer')}
                value={_.get(searchOrder, 'customer')}
              />
            </Form.Item>
            {/* <LoadmoreCustomer data={searchOrder} setData={setSearchOrder} required={false} notSpec={true} /> */}
          </Col>
          <Col span={8}>
            <Form.Item
              label={
                <LabeRequireForm
                  text={intl.formatMessage({ id: 'deliveryFilterTitleOrderType', defaultMessage: 'Order Type' })}
                  req={false}
                />
              }
            >
              <Select
                allowClear={true}
                placeholder={intl.formatMessage({ id: 'deliveryFilterPlaceholderOrderType', defaultMessage: 'Select Order Type' })}
                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                showSearch
                onChange={(value) => onFilterOrder(value, 'orderType')}
                value={_.get(searchOrder, 'orderType')}
              >
                {_.map(_.get(orderData, 'data.orderTypeList'), (item) => (
                  <Option key={item.orderTypeCode}>{item.orderTypeCode}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[24, 2]}>
          <Col span={8}>
            <Form.Item
              label={<LabeRequireForm text={intl.formatMessage({ id: 'deliveryFilterTitleType', defaultMessage: 'Type' })} req={false} />}
            >
              <Select
                allowClear={true}
                placeholder={intl.formatMessage({ id: 'deliveryFilterPlaceholderType', defaultMessage: 'Select Type' })}
                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                showSearch
                onChange={(value) => onFilterOrder(value, 'type')}
                value={_.get(searchOrder, 'type')}
              >
                {_.map(_.get(orderData, 'data.orderDetailTypeList'), (item) => (
                  <Option key={item.code}>{item.txt}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={
                <LabeRequireForm text={intl.formatMessage({ id: 'deliveryFilterTitleSource', defaultMessage: 'Source' })} req={false} />
              }
            >
              <Input
                allowClear={true}
                placeholder={intl.formatMessage({ id: 'deliveryFilterPlaceholderSource', defaultMessage: 'Enter Source' })}
                onChange={(e) => onFilterOrder(e.target.value, 'source')}
                value={_.get(searchOrder, 'source')}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={
                <LabeRequireForm
                  text={intl.formatMessage({ id: 'deliveryFilterTitleDestination', defaultMessage: 'Destination' })}
                  req={false}
                />
              }
            >
              <Input
                allowClear={true}
                placeholder={intl.formatMessage({ id: 'deliveryFilterPlaceholderDestination', defaultMessage: 'Enter Destination' })}
                onChange={(e) => onFilterOrder(e.target.value, 'destination')}
                value={_.get(searchOrder, 'destination')}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[24, 2]}>
          <Col span={8}>
            <Form.Item
              label={
                <LabeRequireForm text={intl.formatMessage({ id: 'deliveryFilterTitleStatus', defaultMessage: 'Status' })} req={false} />
              }
            >
              <Select
                allowClear={true}
                style={{ width: '100%' }}
                placeholder={intl.formatMessage({ id: 'deliveryFilterPlaceholderStatus', defaultMessage: 'Select Status' })}
                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                showSearch
                mode="multiple"
                maxTagCount={3}
                onChange={(value) => onFilterOrder(value, 'status')}
                value={_.get(searchOrder, 'status')}
              >
                {_.map(_.get(orderData, 'data.statusList'), (item) => (
                  <Option key={item.statusCode}>{item.statusTxt}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}></Col>
          <Col span={8} style={{ textAlign: 'right' }}>
            <Form.Item label={<LabeRequireForm text={''} req={false} />}>
              <Button_01 style={{ margin: '0px 0px 0px 10px' }} key="back" btnsize="wd_df" onClick={handleReset}>
                <FormattedMessage id="btnResetFilter" defaultMessage="Reset" />
              </Button_01>
              <Button_02 style={{ margin: '0px 0px 0px 10px' }} key="print" type="primary" btnsize="wd_df" onClick={handleApply}>
                <FormattedMessage id="btnApplyFilter" defaultMessage="Apply" />
              </Button_02>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  };

  return (
    <div style={{ marginTop: '16px' }}>
      <CollapseCustom
        label={intl.formatMessage({ id: `btnFilter`, defaultMessage: 'Filter' })}
        extra={
          <div style={{ display: 'flex' }}>
            <RangePicker
              placeholder={[
                intl.formatMessage({ id: `customerFilterDateStartPH`, defaultMessage: 'Select Date' }),
                intl.formatMessage({ id: `customerFilterDateEndPH`, defaultMessage: 'Select Date' }),
              ]}
              onChange={(e) => onFilterOrder(e, 'searchDate')}
              value={_.get(searchOrder, 'searchDate')}
              format={'DD/MM/YYYY'}
              style={{ marginRight: '12px' }}
            />
            <DeliveryActionColumn />
          </div>
        }
      >
        {formFilter()}
      </CollapseCustom>
    </div>
  );
};

export default DeliveryShipmentAction;
