import React, { useContext, useEffect, useState } from 'react';
import { Form, Row, Col, Input, Divider, Icon, Select, DatePicker, Avatar, AutoComplete } from 'antd';
import { useIntl, FormattedMessage } from 'react-intl';
import NavigatorSVG from '../../../image/navigator.svg';
import ArrowSVG from '../../../image/arrow_location.svg';
import { CreateTaskContext } from './create-task-context';
import './css/index.css';
import _, { debounce } from 'lodash';
import {
  getDisTance,
  getMemberAssignee,
  getTaskTypeByTeam,
  getMemberAssigneeNew,
  getRefAuto,
  getMemberAssigneeNewer,
} from '../../../../controllers/task/create-task';
import moment from 'moment';
import { EditorState, ContentState } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';

const { Option } = Select;

const TabInformation = (props) => {
  const { form } = props;
  const intl = useIntl();
  const { getFieldDecorator, getFieldValue, setFieldsValue } = form;
  const { CreateState, CreateSetState, CreateFNC } = useContext(CreateTaskContext);
  const comId = localStorage.getItem('comId');

  const {
    orgId,
    pageAssigneeAvailable,
    pageSizeAssigneeAvailable,
    pageAssigneeNotAvailable,
    pageSizeAssigneeNotAvailable,
    selectTab,
    searchUnassignee,
    fieldSortAvailable,
    orderSortAvailable,
    fieldSortNotAvailable,
    orderSortNotAvailable,
    visibleAssignee,
    refData,
    orgIDTeam,
    filterTeam,
    listTeam,
    defaultValue,
  } = CreateState;

  const {
    setMemberAvailable,
    setMemberNotAvailable,
    setMemberAvailableArrray,
    setMemberNotAvailableArrray,
    setLoadingAvailable,
    setLoadingNotAvailable,
    setRefData,
    setFilterTeam,
  } = CreateSetState;

  const ref = _.get(CreateState, 'customFieldData');

  const taskTypeId = getFieldValue('taskType');
  const startDate = getFieldValue('startDate');
  const dueDate = getFieldValue('dueDate');
  const formateStartDate = startDate ? moment(startDate, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss') : undefined;
  const formateDueDate = dueDate ? moment(dueDate, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss') : undefined;
  const remarkEnv = process.env.REACT_APP_REMARK_MANDATORY || '';
  const isMandatoryRemark = _.includes(remarkEnv, localStorage.getItem('comCode'));

  const LabeRequire = (props) => {
    const { text, req } = props;
    return (
      <span>
        {text}&nbsp;
        {req ? <span style={{ fontSize: '12px', color: '#ff0000' }}>*</span> : ''}
      </span>
    );
  };

  const NavigatorIcon = () => <img src={NavigatorSVG} style={{ marginBottom: '10px', marginLeft: '3.4px' }}></img>;

  const ArrowIcon = () => <img src={ArrowSVG} style={{ width: '32px' }}></img>;

  // useEffect(() => {
  //   const autoComplete = async () => {
  //     const body = {
  //       entityReference: getFieldValue('reference') ? getFieldValue('reference') : ''
  //     }
  //     // setRefData()
  //   }
  //   autoComplete();
  // }, [getFieldValue('reference')]);

  const getValueItemCode = debounce(async (fieldChang) => {
    if (fieldChang !== '') {
      const body = {
        entityReference: fieldChang,
      };
      const response = await getRefAuto(body);
      setRefData(_.get(response, 'data'));
    }
  }, 200);

  useEffect(() => {
    getTaskTypeByTeamOrg(getFieldValue('team'));
  }, [getFieldValue('team')]);

  const getTaskTypeByTeamOrg = async (org) => {
    // const org = getFieldValue('team');
    const body = {
      orgId: parseInt(org),
      licenseModule: 'transportation',
    };
    if (org) {
      const response = await getTaskTypeByTeam(body);
      if (response.status === 200) {
        CreateSetState.setTaskTypeData(_.get(response, 'data.data.taskTypes'));
        CreateSetState.setCustomFieldData([]);
        CreateSetState.setTaskTypeId();
        // CreateSetState.setDataMemberAssignee([]);
        CreateSetState.setOrgId(org);
        CreateSetState.setFilterAvailable();
        CreateSetState.setFilterCandidate();
        CreateSetState.setFilterOff();
        CreateSetState.setFilterOnGoing();

        if (_.size(_.get(response, 'data.data.taskTypes')) === 1) {
          setFieldsValue({
            ['taskType']: _.get(response, 'data.data.taskTypes[0].taskTypeId'),
          });
        } else {
          setFieldsValue({
            ['taskType']: undefined,
          });
        }
      }
    } else {
      CreateSetState.setOrgId(org);
      CreateSetState.setDataMemberAssignee([]);
      setFilterTeam([]);
      CreateSetState.setListTeam([]);
      setFieldsValue({
        ['taskType']: undefined,
      });
    }
  };

  useEffect(() => {
    const taskType = getFieldValue('taskType');
    if (taskType) {
      const filterData = _.get(CreateState, 'taskTypeData').filter((item) => {
        return item.taskTypeId === taskType;
      });
      CreateSetState.setTaskTypeId(taskType);
    }
  }, [getFieldValue('taskType')]);

  useEffect(() => {
    const customerId = getFieldValue('customer');
    if (customerId) {
      const filterData = _.get(CreateState, 'customerData').filter((item) => {
        return item.customerId === customerId;
      });
      CreateSetState.setCustomerId(customerId);
      CreateSetState.setCustomerName(_.get(filterData, '[0].customerName'));
      setFieldsValue({
        ['contact']: undefined,
      });
      setFieldsValue({
        ['phone']: undefined,
      });
    }
  }, [getFieldValue('customer')]);

  useEffect(() => {
    const customerId = getFieldValue('customer');
    const teamId = getFieldValue('team');
    const dataTeam =
      CreateState.teamData &&
      CreateState.teamData.filter((item) => {
        return item.orgId === teamId;
      });
    if (customerId && _.get(dataTeam, '[0].autoRemarkCustomer') === true) {
      const filterData = _.get(CreateState, 'customerData').filter((item) => {
        return item.customerId === customerId;
      });

      // if (_.get(filterData, '[0].description')) {
      const contentBlock = htmlToDraft(`<code>${_.get(filterData, '[0].description') || ''}</code><br/>`);
      if (contentBlock) {
        const replaceValue1 = _.replace(CreateState.textValue, '<code>', '#ABC#');
        const replaceValue2 = _.replace(replaceValue1, '</code>', '#ABC#');
        const splitValue = _.split(replaceValue2, '#ABC#');
        const stringValue = htmlToDraft(
          `${splitValue[0]}<code>${_.get(filterData, '[0].description') || ''}</code>${
            _.get(splitValue, '[2]') && splitValue[2] !== 'undefined' ? splitValue[2] : ''
          }<br/>`
        );

        if (splitValue[0] !== '') {
          const content = ContentState.createFromBlockArray(stringValue.contentBlocks);
          CreateSetState.setEditorStaet(EditorState.createWithContent(content));
          CreateSetState.setTextValue(
            `${splitValue[0]}<code>${_.get(filterData, '[0].description') || ''}</code>${
              _.get(splitValue, '[2]') && splitValue[2] !== 'undefined' ? splitValue[2] : ''
            }<br/>`
          );
        } else {
          const content = ContentState.createFromBlockArray(contentBlock.contentBlocks);
          CreateSetState.setEditorStaet(EditorState.createWithContent(content));
          CreateSetState.setTextValue(`<code>${_.get(filterData, '[0].description')}</code><br/>`);
        }
      }
      // }
    } else {
      const replaceValue1 = _.replace(CreateState.textValue, '<code>', '#ABC#');
      const replaceValue2 = _.replace(replaceValue1, '</code>', '#ABC#');
      const splitValue = _.split(replaceValue2, '#ABC#');
      const stringValue = htmlToDraft(
        `${splitValue[0]}<code></code>${_.get(splitValue, '[2]') && splitValue[2] !== 'undefined' ? splitValue[2] : ''}`
      );
      if (splitValue[0] !== '') {
        const content = ContentState.createFromBlockArray(stringValue.contentBlocks);
        CreateSetState.setEditorStaet(EditorState.createWithContent(content));
        CreateSetState.setTextValue(
          `${splitValue[0]}<code></code>${_.get(splitValue, '[2]') && splitValue[2] !== 'undefined' ? splitValue[2] : ''}`
        );
      }
    }
  }, [getFieldValue('customer'), getFieldValue('team')]);

  useEffect(() => {
    const contactId = getFieldValue('contact');
    if (contactId) {
      const filterData = _.get(CreateState, 'contactData').filter((item) => {
        return item.customerContactId === contactId;
      });
      CreateSetState.setStatusSave(_.get(filterData, '[0].customerContactId'));
      setFieldsValue({
        ['phone']: _.get(filterData, '[0].customerContactPhone'),
      });
    }
  }, [getFieldValue('contact'), _.size(CreateState.temporaryData)]);

  useEffect(() => {
    if (_.get(CreateState, 'newCustomer')) {
      CreateSetState.setCustomerId(_.get(CreateState, 'newCustomer.customerId'));
      CreateSetState.setCustomerName(_.get(CreateState, 'newCustomer.customerName'));
      setTimeout(() => {
        setFieldsValue({
          ['customer']: _.get(CreateState, 'newCustomer.customerId'),
        });
      }, 2000);
    }
  }, [_.get(CreateState, 'newCustomer.customerId')]);

  useEffect(() => {
    if (_.get(CreateState, 'newContact')) {
      setTimeout(() => {
        setFieldsValue({
          ['contact']: _.get(CreateState, 'newContact'),
        });
      }, 1000);
    }
  }, [_.get(CreateState, 'newContact')]);

  const polyLineGoogleMap = () => {
    if (_.get(CreateState, 'createFromData') && _.get(CreateState, 'createToData')) {
      window.open(
        `https://www.google.es/maps/dir/'${parseFloat(_.get(CreateState, 'createFromData.lat'))},${parseFloat(
          _.get(CreateState, 'createFromData.lng')
        )}'/'${parseFloat(_.get(CreateState, 'createToData.lat'))},${parseFloat(_.get(CreateState, 'createToData.lng'))}'`
      );
    }
  };

  useEffect(() => {
    const getDistancePolypline = async () => {
      if (_.get(CreateState, 'createFromData') && _.get(CreateState, 'createToData')) {
        const bodyLatlng = {
          fromLat: _.get(CreateState, 'createFromData.lat'),
          fromLng: _.get(CreateState, 'createFromData.lng'),
          toLat: _.get(CreateState, 'createToData.lat'),
          toLng: _.get(CreateState, 'createToData.lng'),
        };
        const responseDistance = await getDisTance(bodyLatlng, 'transportation');
        CreateSetState.setDisTancePolyline(_.get(responseDistance, 'data.data.distance'));
      }
    };

    getDistancePolypline();
  }, [_.get(CreateState, 'createFromData.addressBookId'), _.get(CreateState, 'createToData.addressBookId')]);

  ////available
  useEffect(() => {
    const getAvailableMember = async () => {
      const payload = {
        search: {
          name: searchUnassignee,
          phone: searchUnassignee,
        },
        orderBy: fieldSortAvailable,
        orderType: orderSortAvailable,
        page: searchUnassignee === '' ? pageAssigneeAvailable : 1,
        limit: pageSizeAssigneeAvailable,
        orgId: orgId,
        taskTypeId: taskTypeId ? taskTypeId : undefined,
        isReloadCandidate: false,
        toLat: _.get(CreateState, 'createFromData.lat') || undefined, //มีก็ส่งไม่มีก็ส่ง
        toLng: _.get(CreateState, 'createFromData.lng') || undefined, //มีก็ส่งไม่มีก็ส่ง
        startDate: formateStartDate, //มีก็ส่งไม่มีก็ส่ง
        endDate: formateDueDate, //มีก็ส่งไม่มีก็ส่ง
        taskId: undefined, //มีก็ส่งไม่มีก็ส่ง
      };

      const payloadAll = {
        comId: comId,
        orgId: orgId ? [orgId] : orgIDTeam,
        searchAll: searchUnassignee,
        pageNumber: searchUnassignee === '' ? pageAssigneeAvailable : 1,
        limit: pageSizeAssigneeAvailable,
        orderBy: fieldSortAvailable,
        orderType: orderSortAvailable,
      };

      const responseAvailable = await getMemberAssigneeNewer(payloadAll);

      // const responseAvailable = orgId ? await getMemberAssigneeNew(payload, 'available') : await getMemberAssigneeNewer(payloadAll);
      // const arrayAvailable = _.get(responseAvailable.data, 'data.membersCandidate').map((el) => {
      //   return {
      //     distance: el.distance,
      //     index: el.index,
      //     isAssignee: el.isAssignee,
      //     isWaringReset: el.isWaringReset,
      //     memComId: el.memComId,
      //     memOrgId: el.memOrgId,
      //     orgId: el.orgId,
      //     name: el.name,
      //     phone: el.phone,
      //     profile: el.profile,
      //     status: el.status,
      //     subtasks: el.subtasks,
      //     color: '#1D3557',
      //     statusButton: false,
      //   };
      // });
      const arrayAvailable = _.get(responseAvailable, 'data.data.memberList').map((el) => {
        return {
          ...el,
          teamName: _.get(el, 'organization_details.teamName'),
          profile: _.get(el, 'profile_img'),
          memComId: _.get(el, 'mem_com_id'),
          memOrgId: _.get(el, 'organization_details.memOrgId'),
          orgId: _.get(el, 'org_id'),
        };
      });

      setMemberAvailable(_.get(responseAvailable.data, 'data'));
      setMemberAvailableArrray(arrayAvailable);
      setLoadingAvailable(false);
    };
    // if (taskTypeId && orgId && visibleAssignee === true) {
    if (visibleAssignee === true) {
      setLoadingAvailable(true);
      getAvailableMember();
    }
  }, [
    _.get(CreateState, 'createFromData.addressBookId'),
    getFieldValue('taskType'),
    getFieldValue('startDate'),
    getFieldValue('dueDate'),
    orgId,
    selectTab,
    visibleAssignee,
    searchUnassignee,
    fieldSortAvailable,
    orderSortAvailable,
    pageAssigneeAvailable,
    pageSizeAssigneeAvailable,
  ]);

  ////not available
  useEffect(() => {
    setLoadingNotAvailable(true);
    const getAvailableMember = async () => {
      const payload = {
        search: {
          name: searchUnassignee,
          phone: searchUnassignee,
        },
        orderBy: fieldSortNotAvailable,
        orderType: orderSortNotAvailable,
        page: searchUnassignee === '' ? pageAssigneeNotAvailable : 1,
        limit: pageSizeAssigneeNotAvailable,
        orgId: orgId,
        taskTypeId: taskTypeId ? taskTypeId : undefined,
        isReloadCandidate: false,
        toLat: _.get(CreateState, 'createFromData.lat') || undefined, //มีก็ส่งไม่มีก็ส่ง
        toLng: _.get(CreateState, 'createFromData.lng') || undefined, //มีก็ส่งไม่มีก็ส่ง
        startDate: formateStartDate, //มีก็ส่งไม่มีก็ส่ง
        endDate: formateDueDate, //มีก็ส่งไม่มีก็ส่ง
        taskId: undefined, //มีก็ส่งไม่มีก็ส่ง
      };
      const responseNotAvailable = await getMemberAssigneeNew(payload, 'notavailable');
      const arrayNotAvailable = _.get(responseNotAvailable.data, 'data.membersCandidate').map((el) => {
        return {
          distance: el.distance,
          index: el.index,
          isAssignee: el.isAssignee,
          isWaringReset: el.isWaringReset,
          memComId: el.memComId,
          memOrgId: el.memOrgId,
          orgId: el.orgId,
          name: el.name,
          phone: el.phone,
          profile: el.profile,
          status: el.status,
          subtasks: el.subtasks,
          color: '#1D3557',
          statusButton: false,
        };
      });
      setMemberNotAvailable(_.get(responseNotAvailable.data, 'data'));
      setMemberNotAvailableArrray(arrayNotAvailable);
      setLoadingNotAvailable(false);
    };
    if (taskTypeId && orgId && visibleAssignee === true) {
      getAvailableMember();
    }
  }, [
    _.get(CreateState, 'createFromData.addressBookId'),
    getFieldValue('taskType'),
    getFieldValue('startDate'),
    getFieldValue('dueDate'),
    orgId,
    selectTab,
    visibleAssignee,
    searchUnassignee,
    fieldSortNotAvailable,
    orderSortNotAvailable,
    pageAssigneeNotAvailable,
    pageSizeAssigneeNotAvailable,
  ]);

  useEffect(() => {
    if (_.size(filterTeam) > 0) {
      setFieldsValue({
        ['team']: filterTeam,
      });
    } else {
      setFieldsValue({
        ['team']: undefined,
      });
    }
  }, [filterTeam]);

  console.log('listTeamxxx', listTeam, _.get(CreateState, 'teamData'))

  useEffect(() => {
    const getInfo = async () => {

      const payloadAll = {
        comId: comId,
        orgId: [_.get(defaultValue, 'orgId')],
        searchAll: _.get(defaultValue, 'assigneeName'),
        pageNumber: searchUnassignee === '' ? pageAssigneeAvailable : 1,
        limit: pageSizeAssigneeAvailable,
        orderBy: fieldSortAvailable,
        orderType: orderSortAvailable,
      };
  
      const responseAvailable = await getMemberAssigneeNewer(payloadAll);

      const arrayAvailable = _.get(responseAvailable, 'data.data.memberList').map((el) => {
        return {
          ...el,
          teamName: _.get(el, 'organization_details.teamName'),
          profile: _.get(el, 'profile_img'),
          memComId: _.get(el, 'mem_com_id'),
          memOrgId: _.get(el, 'organization_details.memOrgId'),
          orgId: _.get(el, 'org_id'),
        };
      });

      CreateSetState.setDataMemberAssignee(arrayAvailable);

      CreateSetState.setOrgIDTeam([_.get(defaultValue, 'orgId')])
      setFilterTeam(_.get(defaultValue, 'orgId'));

      // setMemberAvailable(_.get(responseAvailable.data, 'data'));
      // setMemberAvailableArrray(arrayAvailable);
      setLoadingAvailable(false);
    }

    if(defaultValue) {
      getInfo()
      setLoadingAvailable(true)
    }

  }, [defaultValue]);

  useEffect(() => {
    if(_.get(defaultValue, 'orgId') && _.get(CreateState, 'teamData')) {
      CreateSetState.setListTeam(_.filter(_.get(CreateState, 'teamData'), team => team.orgId === _.get(defaultValue, 'orgId')));
    }
  }, [_.get(defaultValue, 'orgId'), _.get(CreateState, 'teamData')])

  getFieldDecorator('keys', { initialValue: [] });
  const formItems =
    ref &&
    ref.map((k, index) => {
      return (
        <div>
          {
            <Col span={8}>
              <Form.Item
                className="create-task-tab-information-form-item"
                label={
                  <LabeRequire
                    text={intl.formatMessage({
                      id: `${k.titleCode}`,
                      defaultMessage: `${k.defaultTitle}`,
                    })}
                    req={k.required && k.required !== null ? true : false}
                  />
                }
                required={false}
                key={k.entityToField}
              >
                {getFieldDecorator(`${k.entityToField}`, {
                  rules: [
                    {
                      required: k.required && k.required !== null ? true : false,
                      whitespace: true,
                      message: `${intl.formatMessage({ id: k.placeholderCode, defaultMessage: k.displayTitle })} `,
                    },
                  ],
                })(
                  <Input
                    placeholder={`${intl.formatMessage({ id: k.placeholderCode, defaultMessage: k.displayTitle })} `}
                    autoComplete="off"
                  />
                )}
              </Form.Item>
            </Col>
          }
        </div>
      );
    });

  const validatorStartDate = (rule, value, callback) => {
    const startDate = value;
    const dueDate = getFieldValue(`dueDate`);
    const formateStartDate = moment(startDate, 'YYYY-MM-DD HH:mm')
      .format('YYYY-MM-DD HH:mm')
      .toString();
    const formateDueDate = moment(dueDate, 'YYYY-MM-DD HH:mm')
      .format('YYYY-MM-DD HH:mm')
      .toString();
    if (startDate && dueDate) {
      if (startDate > dueDate) {
        callback(
          <FormattedMessage id="monitorTabDetailModalDateTimeValidatorDueDate" defaultMessage="Start date should not less than due date" />
        );
      } else {
        if (formateStartDate === formateDueDate) {
          callback(
            <FormattedMessage id="monitorTabDetailModalDateTimeValidatorStartTime2" defaultMessage="Cannot select the same date and time" />
          );
        } else {
          callback();
        }
      }
    } else {
      callback();
    }
  };

  const validatorDueDate = (rule, value, callback) => {
    const startDate = getFieldValue(`startDate`);
    const dueDate = value;
    const formateStartDate = moment(startDate, 'YYYY-MM-DD HH:mm:ss')
      .format('YYYY-MM-DD HH:mm')
      .toString();
    const formateDueDate = moment(dueDate, 'YYYY-MM-DD HH:mm:ss')
      .format('YYYY-MM-DD HH:mm')
      .toString();

    if (startDate && dueDate) {
      if (startDate > dueDate) {
        callback(
          <FormattedMessage id="monitorTabDetailModalDateTimeValidatorDueDate" defaultMessage="Start date should not less than due date" />
        );
      } else {
        if (formateStartDate === formateDueDate) {
          callback(
            <FormattedMessage id="monitorTabDetailModalDateTimeValidatorStartTime2" defaultMessage="Cannot select the same date and time" />
          );
        } else {
          callback();
        }
      }
    } else {
      callback();
    }
  };

  useEffect(() => {
    if (_.size(CreateState.temporaryData) !== 0) {
      const filterData =
        CreateState.contactData &&
        CreateState.contactData.filter((item) => {
          return item.customerContactId !== 'temporary';
        });
      const concatArry = CreateState.temporaryData && CreateState.temporaryData.concat(filterData);
      CreateSetState.setContactData(concatArry);
      CreateSetState.setTemporaryData([]);
    }
  }, [_.size(CreateState.temporaryData)]);

  const childrenRef =
    refData &&
    refData.map((item) => (
      <Option key={item.referenceNo} label={item.referenceNo}>
        {item.referenceNo} · {item.referenceName}
      </Option>
    ));

  const onBlurRef = () => {
    // const filter = refData && refData.filter(item => { return item.referenceNo === getFieldValue('reference') })
    // if (_.size(filter) === 0) {
    //   setFieldsValue({
    //     ['reference']: undefined
    //   });
    // }
    // console.log('reference ::', getFieldValue('reference'))
  };

  return (
    <div className="create-task-tab-information">
      <Form>
        <Row>
          <Row gutter={[24]}>
            <Col span={8}>
              <Form.Item
                className="create-task-tab-information-form-item"
                label={<LabeRequire text={intl.formatMessage({ id: 'createTaskMntLbTaskNo', defaultMessage: 'Task No.' })} req={false} />}
              >
                {getFieldDecorator('taskNo', {
                  rules: [
                    {
                      required: false,
                      message: intl.formatMessage({ id: 'createTaskMntLbTaskNo', defaultMessage: 'Task No.' }),
                    },
                  ],
                })(
                  <Input
                    className="create-task-tab-information-input-style"
                    placeholder={intl.formatMessage({ id: 'createTaskMntLbTaskNo', defaultMessage: 'Task No.' })}
                    disabled={true}
                  />
                )}
              </Form.Item>
            </Col>
            <Col span={16}>
              <Form.Item
                className="create-task-tab-information-form-item"
                label={<LabeRequire text={intl.formatMessage({ id: 'createTaskMntLbTaskName', defaultMessage: 'Task Name' })} req={true} />}
              >
                {getFieldDecorator('taskName', {
                  rules: [
                    {
                      required: true,
                      message: intl.formatMessage({ id: 'createTaskMntVLTaskName', defaultMessage: 'Please enter a Task Name' }),
                    },
                  ],
                })(
                  <Input
                    className="create-task-tab-information-input-style"
                    placeholder={intl.formatMessage({ id: 'createTaskMntPHTaskName', defaultMessage: 'Enter a Task Name' })}
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[24]}>
            <Col span={8}>
              <Form.Item
                className="create-task-tab-information-form-item"
                label={<LabeRequire text={intl.formatMessage({ id: 'createTaskMntLBTeam', defaultMessage: 'Team' })} req={true} />}
              >
                {getFieldDecorator('team', {
                  initialValue: _.get(defaultValue, 'orgId') ? _.get(defaultValue, 'orgId') : undefined,
                  rules: [
                    {
                      required: true,
                      message: intl.formatMessage({ id: 'createTaskMntVLTeam', defaultMessage: 'Please select a Team' }),
                    },
                  ],
                })(
                  <Select allowClear placeholder={intl.formatMessage({ id: 'createTaskMntPHTeam', defaultMessage: 'Select a Team' })}>
                    {CreateState.teamData &&
                      _.map(_.size(listTeam) > 0 ? listTeam : _.get(CreateState, 'teamData'), (item) => (
                        <Option key={item.orgId}>{item.name}</Option>
                      ))}
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                className="create-task-tab-information-form-item"
                label={<LabeRequire text={intl.formatMessage({ id: 'createTaskMntLBTaskType', defaultMessage: 'Task Type' })} req={true} />}
              >
                {getFieldDecorator('taskType', {
                  // initialValue: _.size(CreateState.taskTypeData) === 1 ? _.get(CreateState, 'taskTypeData[0].taskTypeId') : undefined,
                  rules: [
                    {
                      required: true,
                      message: intl.formatMessage({ id: 'createTaskMntVLTaskType', defaultMessage: 'Please select a Task Type' }),
                    },
                  ],
                })(
                  <Select
                    placeholder={intl.formatMessage({ id: 'createTaskMntPHTaskType', defaultMessage: 'Select a Task Type' })}
                    showSearch
                    filterOption={(input, option) =>
                      option.props.children
                        .toString()
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    disabled={getFieldValue('team') ? false : true}
                  >
                    {CreateState.taskTypeData &&
                      CreateState.taskTypeData.map((item) => <Option key={item.taskTypeId}>{item.taskTypeName}</Option>)}
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                className="create-task-tab-information-form-item"
                label={<LabeRequire text={intl.formatMessage({ id: 'createTaskMntLBPriority', defaultMessage: 'Priority' })} req={true} />}
              >
                {getFieldDecorator('priority', {
                  initialValue: _.get(CreateState, 'defaultPriority') ? _.get(CreateState, 'defaultPriority.priorityId') : undefined,
                  rules: [
                    {
                      required: true,
                      message: intl.formatMessage({ id: 'createTaskMntPHPriority', defaultMessage: 'Select a Priority' }),
                    },
                  ],
                })(
                  <Select
                    showSearch
                    filterOption={(input, option) =>
                      option.props.children
                        .toString()
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    placeholder={intl.formatMessage({ id: 'createTaskMntPHPriority', defaultMessage: 'Select a Priority' })}
                  >
                    {CreateState.priorityData &&
                      CreateState.priorityData.map((item) => (
                        <Option key={item.priorityId}>
                          <img src={item.icon} style={{ width: '7px', height: '7px' }}></img> {item.name}
                        </Option>
                      ))}
                  </Select>
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[24]}>
            <Col span={8}>
              <Form.Item
                className="create-task-tab-information-form-item"
                label={
                  <LabeRequire
                    text={intl.formatMessage({ id: 'createTaskMntLBCustomer', defaultMessage: 'Select a Customer' })}
                    req={true}
                  />
                }
              >
                {getFieldDecorator('customer', {
                  rules: [
                    {
                      required: true,
                      message: intl.formatMessage({ id: 'createTaskMntVLCustomer', defaultMessage: 'Please select a Customer' }),
                    },
                  ],
                })(
                  <Select
                    placeholder={intl.formatMessage({ id: 'createTaskMntPHCustomer', defaultMessage: 'Select a Customer' })}
                    showSearch
                    filterOption={(input, option) =>
                      option.props.children
                        .toString()
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    dropdownRender={(menu) => (
                      <div>
                        {menu}
                        <Divider style={{ margin: '4px 0' }} />
                        <div
                          style={{ padding: '4px 8px', cursor: 'pointer' }}
                          onMouseDown={(e) => e.preventDefault()}
                          onClick={() => CreateSetState.setVisibleCustomer(true)}
                        >
                          <Icon type="plus" className="customer-icon-plus" />
                          <span className="text-add-customer"> Add Customer</span>
                        </div>
                      </div>
                    )}
                  >
                    {CreateState.customerData &&
                      CreateState.customerData.map((item) => (
                        <Option title={item.customerName} key={item.customerId}>
                          {item.customerName}
                        </Option>
                      ))}
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                className="create-task-tab-information-form-item"
                label={<LabeRequire text={intl.formatMessage({ id: 'createTaskMntLBContact', defaultMessage: 'Contact' })} req={true} />}
              >
                {getFieldDecorator('contact', {
                  rules: [
                    {
                      required: true,
                      message: intl.formatMessage({ id: 'createTaskMntVLContact', defaultMessage: 'Please select a Contact' }),
                    },
                  ],
                })(
                  <Select
                    placeholder={intl.formatMessage({ id: 'createTaskMntPHContact', defaultMessage: 'Select a Contact' })}
                    showSearch
                    filterOption={(input, option) =>
                      option.props.children
                        .toString()
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    disabled={getFieldValue('customer') ? false : true}
                    optionLabelProp="label"
                    dropdownRender={(menu) => (
                      <div>
                        {menu}
                        <Divider style={{ margin: '4px 0' }} />
                        <div
                          style={{ padding: '4px 8px', cursor: 'pointer' }}
                          onMouseDown={(e) => e.preventDefault()}
                          onClick={() => {
                            CreateSetState.setVisibleContact(true);
                          }}
                        >
                          <Icon type="plus" className="customer-icon-plus" />
                          <span className="text-add-customer">
                            <FormattedMessage id="btnAdd" defaultMessage="Add" />{' '}
                            <FormattedMessage id="customerContactCardHeader" defaultMessage="Contact" />
                          </span>
                        </div>
                      </div>
                    )}
                  >
                    {CreateState.contactData &&
                      CreateState.contactData.map((item) => (
                        <Option key={item.customerContactId} label={item.customerContactName}>
                          <Row>
                            <Col span={6}>
                              <Avatar size="large" icon="user" />
                            </Col>
                            <Col span={18}>
                              <div style={{ fontSize: '13px', color: '#1D3557' }}>{item.customerContactName}</div>
                              <div style={{ fontSize: '11px', color: '#1D3557' }}>
                                {item.position} · {item.customerContactPhone}
                              </div>
                            </Col>
                          </Row>
                        </Option>
                      ))}
                  </Select>
                )}
                {CreateState.statusSave === 'temporary' ? (
                  <div className="create-task-status-save" onClick={CreateFNC.handleSaveContactTemporary}>
                    <FormattedMessage id="monitorTabDetailSaveContactCustomer" defaultMessage="Save to Customer Contact" />
                  </div>
                ) : null}
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                className="create-task-tab-information-form-item"
                label={<LabeRequire text={intl.formatMessage({ id: 'createTaskMntLBPhone', defaultMessage: 'Phone' })} req={false} />}
              >
                {getFieldDecorator('phone', {
                  rules: [
                    {
                      required: false,
                      message: intl.formatMessage({ id: 'createTaskMntLBPhone', defaultMessage: 'Phone' }),
                    },
                  ],
                })(
                  <Input
                    className="create-task-tab-information-input-style"
                    placeholder={intl.formatMessage({ id: 'createTaskMntLBPhone', defaultMessage: 'Phone' })}
                    disabled={getFieldValue('contact') ? false : true}
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[24]}>
            <Col span={8}>
              <Form.Item
                className="create-task-tab-information-form-item"
                label={
                  <LabeRequire text={intl.formatMessage({ id: 'createTaskMntLBStartDate', defaultMessage: 'Start Date' })} req={true} />
                }
              >
                {getFieldDecorator('startDate', {
                  initialValue: _.get(defaultValue, 'startDate') ? _.get(defaultValue, 'startDate') : undefined,
                  rules: [
                    {
                      required: true,
                      // required: getFieldValue('dueDate') ? true : false,
                      message: intl.formatMessage({ id: 'createTaskMntPHStartDate', defaultMessage: 'Select Start Date and Time' }),
                    },
                    {
                      validator: validatorStartDate,
                    },
                  ],
                })(
                  <DatePicker
                    showTime={{ format: 'HH:mm' }}
                    format="YYYY-MM-DD HH:mm"
                    placeholder={intl.formatMessage({ id: 'createTaskMntPHStartDate', defaultMessage: 'Select Start Date and Time' })}
                    style={{ width: '100%' }}
                  />
                )}
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                className="create-task-tab-information-form-item"
                label={<LabeRequire text={intl.formatMessage({ id: 'createTaskMntLBEndDate', defaultMessage: 'Due Date' })} req={true} />}
              >
                {getFieldDecorator('dueDate', {
                  initialValue: _.get(defaultValue, 'dueDate') ? _.get(defaultValue, 'dueDate') : undefined,
                  rules: [
                    {
                      required: true,
                      // required: getFieldValue('startDate') ? true : false,
                      message: intl.formatMessage({ id: 'createTaskMntPHEndDate', defaultMessage: 'Select Due Date and Time' }),
                    },
                    {
                      validator: validatorDueDate,
                    },
                  ],
                })(
                  <DatePicker
                    showTime={{ format: 'HH:mm' }}
                    format="YYYY-MM-DD HH:mm"
                    placeholder={intl.formatMessage({ id: 'createTaskMntPHEndDate', defaultMessage: 'Select Due Date and Time' })}
                    style={{ width: '100%' }}
                  />
                )}
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                className="create-task-tab-information-form-item"
                label={
                  <LabeRequire text={intl.formatMessage({ id: 'createTaskMntLBReference', defaultMessage: 'Reference' })} req={false} />
                }
              >
                {getFieldDecorator('reference', {
                  rules: [
                    {
                      required: false,
                      message: intl.formatMessage({ id: 'createTaskMntLBReference', defaultMessage: 'Reference' }),
                    },
                  ],
                })(
                  <AutoComplete
                    placeholder={intl.formatMessage({ id: 'createTaskMntLBReference', defaultMessage: 'Reference' })}
                    dataSource={refData}
                    filterOption={false}
                    style={{ width: '100%' }}
                    onSearch={getValueItemCode}
                    optionLabelProp="label"
                    onBlur={onBlurRef}
                  >
                    {childrenRef}
                  </AutoComplete>
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[24]}>{formItems}</Row>
          <Row gutter={[24]}>
            <Col span={24}>
              <Form.Item
                className="create-task-tab-information-form-item"
                label={
                  <LabeRequire
                    text={intl.formatMessage({ id: 'createTaskMntLBRemark', defaultMessage: 'Remark' })}
                    req={isMandatoryRemark}
                  />
                }
              >
                {getFieldDecorator('remark', {
                  rules: [
                    {
                      required: isMandatoryRemark,
                      message: intl.formatMessage({ id: 'createTaskMntVLRemark', defaultMessage: 'Please Enter Remark' }),
                    },
                  ],
                })(
                  <Input
                    className="create-task-tab-information-input-style"
                    placeholder={intl.formatMessage({ id: 'createTaskMntPHRemark', defaultMessage: 'Enter Remark' })}
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Divider type="horizontal" style={{ margin: '24px 0px' }} />
            <div style={{ paddingBottom: '5px' }}>
              <span className="create-task-tab-text-address">
                <FormattedMessage id="createTaskMntTitleAddress" defaultMessage="Address" />{' '}
              </span>
              <Icon component={NavigatorIcon} className="create-task-tab-text-icon" onClick={polyLineGoogleMap} />
            </div>
            <Row>
              <Col span={10}>
                <Form.Item
                  className="create-task-tab-information-form-item"
                  label={<LabeRequire text={intl.formatMessage({ id: 'createTaskMntLBFrom', defaultMessage: 'From' })} req={false} />}
                >
                  <Row>
                    <Col span={1}>
                      <Icon type="environment" style={{ fontSize: '12px', color: '#0c4da2', marginRight: '5px' }} />
                    </Col>
                    <Col span={22}>
                      {_.get(CreateState, 'createFromData') ? (
                        <div>
                          <div
                            onClick={() => CreateSetState.setVisibleAddCompanyAddress(true)}
                            style={{ cursor: 'pointer', marginLeft: '11px' }}
                          >
                            <span className="task-detail-information-text-address-value">{`${_.get(
                              CreateState,
                              'createFromData.name'
                            )} · ${_.get(CreateState, 'createFromData.address')}`}</span>
                          </div>
                          {_.get(CreateState, 'createFromData.status') === 'normal_address' ? (
                            <div
                              onClick={CreateFNC.saveFromAddress}
                              className="create-task-status-save"
                              style={{ cursor: 'pointer', marginLeft: '11px' }}
                            >
                              <FormattedMessage id="monitorTabDetailSaveAddressCompany" defaultMessage="Save to Company Address Book" />
                            </div>
                          ) : null}
                        </div>
                      ) : (
                        <div>
                          {getFieldDecorator('fromLocation', {
                            // rules: [
                            //   {
                            //     required: false,
                            //     message: intl.formatMessage({ id: 'createTaskMntVLFrom', defaultMessage: 'Please select a From Location' }),
                            //   },
                            // ],
                          })(
                            <Input
                              readOnly={true}
                              placeholder={intl.formatMessage({ id: 'createTaskMntPHFrom', defaultMessage: 'Select a From Location' })}
                              onClick={() => (getFieldValue('customer') ? CreateSetState.setVisibleAddCompanyAddress(true) : null)}
                              style={
                                getFieldValue('customer')
                                  ? { border: 'unset', cursor: 'pointer' }
                                  : { border: 'unset', cursor: 'not-allowed' }
                              }
                            />
                          )}
                        </div>
                      )}
                    </Col>
                  </Row>
                </Form.Item>
              </Col>
              <Col span={4} className="create-task-tab-information-vertical-icon">
                <div>
                  <Icon component={ArrowIcon} />
                </div>
                <div>
                  <span style={{ fontSize: '12px', color: '#707070' }}>
                    {_.get(CreateState, 'disTancePolyline') ? ` ~ ${_.get(CreateState, 'disTancePolyline')}` : ''}
                  </span>
                </div>
              </Col>
              <Col span={10}>
                <Form.Item
                  className="create-task-tab-information-form-item"
                  label={
                    <LabeRequire
                      text={intl.formatMessage({ id: 'createTaskMntLBDestination', defaultMessage: 'Destination' })}
                      req={true}
                    />
                  }
                >
                  <Row>
                    <Col span={1}>
                      <Icon type="environment" style={{ fontSize: '12px', color: '#1D3557', marginRight: '5px' }} />
                    </Col>
                    <Col span={22}>
                      {_.get(CreateState, 'createToData') ? (
                        <div>
                          <div
                            onClick={() => CreateSetState.setVisibleAddCustomerAddress(true)}
                            style={{ cursor: 'pointer', marginLeft: '11px' }}
                          >
                            <span className="task-detail-information-text-address-value">{`${_.get(
                              CreateState,
                              'createToData.name'
                            )} · ${_.get(CreateState, 'createToData.address')}`}</span>
                          </div>
                          {_.get(CreateState, 'createToData.status') === 'normal_address' ? (
                            <div
                              onClick={CreateFNC.saveToAddress}
                              className="create-task-status-save"
                              style={{ cursor: 'pointer', marginLeft: '11px' }}
                            >
                              <FormattedMessage id="monitorTabDetailSaveAddressCustomer" defaultMessage="Save to Customer Address Book" />
                            </div>
                          ) : null}
                        </div>
                      ) : (
                        <div>
                          {getFieldDecorator('toLocation', {
                            rules: [
                              {
                                required: true,
                                message: intl.formatMessage({
                                  id: 'createTaskMntVLDestination',
                                  defaultMessage: 'Please select a Destination Location',
                                }),
                              },
                            ],
                          })(
                            <Input
                              readOnly={true}
                              placeholder={intl.formatMessage({
                                id: 'createTaskMntPHDestination',
                                defaultMessage: 'Select a Destination Location',
                              })}
                              onClick={() => (getFieldValue('customer') ? CreateSetState.setVisibleAddCustomerAddress(true) : null)}
                              style={
                                getFieldValue('customer')
                                  ? { border: 'unset', cursor: 'pointer' }
                                  : { border: 'unset', cursor: 'not-allowed' }
                              }
                            />
                          )}
                        </div>
                      )}
                    </Col>
                  </Row>
                </Form.Item>
              </Col>
            </Row>
          </Row>
        </Row>
      </Form>
    </div>
  );
};

const TabInfo = Form.create({
  name: 'modal_form',
})(TabInformation);

export default TabInfo;
